import React from "react";
import { Route, Routes } from "react-router-dom";
// internal
import BackToTop from "../components/BackToTop";
import AnimateMouse from "../components/common/AnimateMouse";
import ContextProvider from "../components/context/ContextProvider";
import Home from "../components/Home/Home";
import HomeThree from "../components/HomeThree/HomeThree";
import HomeTwo from "../components/HomeTwo/HomeTwo";
import About from "../components/Pages/About/About";
import Blog from "../components/Pages/Blog/Blog";
import BlogDetails from "../components/Pages/BlogDetails/BlogDetails";
import ContactUs from "../components/Pages/ContactUs/ContactUs";
import FAQ from "../components/Pages/FAQ/FAQ";
import Portfolio from "../components/Pages/Portfolio/Portfolio";
import PortfolioDetails from "../components/Pages/PortfolioDetails/PortfolioDetails";
import Price from "../components/Pages/Price/Price";
import Service from "../components/Pages/Service/Service";
import ServiceDetails from "../components/Pages/ServiceDetails/ServiceDetails";
import TeamDetails from "../components/Pages/TeamDetails/TeamDetails";
import TeamPage from "../components/Pages/TeamPage/TeamPage";
import EarlyStageAdvisory from "../components/Pages/Service/EarlyStageAdvisory";
import TransactionSupport from "../components/Pages/Service/TransactionSupport";
import InvestmentBanking from "../components/Pages/Service/InvestmentBanking";

const AppNavigation = () => {
  return (
    <ContextProvider>
      <AnimateMouse />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/early-stage-advisory" element={<EarlyStageAdvisory />} />
        <Route path="/transaction-support" element={<TransactionSupport />} />
        <Route path="/investment-banking" element={<InvestmentBanking />} />
        {/* <Route path="/home-2" element={<HomeTwo />} /> */}
        {/* <Route path="/home-3" element={<HomeThree />} /> */}
        {/* <Route path="/about" element={<About />} /> */}
        <Route path="/team" element={<TeamPage />} />
        {/* <Route path="/team-details" element={<TeamDetails />} /> */}
        {/* <Route path="/service" element={<Service />} /> */}
        {/* <Route path="/service-details" element={<ServiceDetails />} /> */}
        {/* <Route path="/price" element={<Price />} /> */}
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/faq" element={<FAQ />} />
        {/* <Route path="/portfolio" element={<Portfolio />} /> */}
        {/* <Route path="/portfolio-details" element={<PortfolioDetails />} /> */}
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-details" element={<BlogDetails />} />
      </Routes>
      <BackToTop />
    </ContextProvider>
  );
};

export default AppNavigation;
