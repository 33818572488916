import React from "react";
export default function TransactionSupportAbout({ highlights }) {
  return (
    <div className="container pt-70 pb-70">
      <div className="row">
        <div className="esa-about-left col-md-12 col-lg-3 offset-lg-1">
          <div className="d-flex flex-row">
            {/* <div className="hyphen"></div> */}
            <h5 className="hyphenated">EXPLORE OUR SERVICES</h5>
          </div>
          <h2>Qualified for your needs</h2>
        </div>
        <div className=" col-md-12 col-lg-7 d-flex flex-row">
          <div className="divider"></div>
          <div className="ts-about-right w-100">
            <p>
              In the world of startups, successful transactions are the building
              blocks of growth. Our Transaction Support service streamlines the
              complex process of financial due diligence, valuation, termsheets,
              and shareholder agreements (SHA/SSA), ensuring that every detail
              is managed with precision and care. Followed by list of services
              we offer and brief about them.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
