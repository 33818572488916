import React from "react";
import Brands from "../../common/Brands/Brands";
import Contact from "../../common/Contact/Contact";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import Testimonial from "../../common/Testimonial/Testimonial";
import OurService from "./OurService";
import ServiceAbout from "./ServiceAbout";
import ServiceBanner from "./ServiceBanner";
import EarlyStageAdvisoryServicesAndAbout from "../../EarlyStageAdvisory/EarlyStageAdvisoryServicesAndAbout";
import TopCtaArea from "../../Home/TopCtaArea";
import PriceDetails from "../Price/PriceDetails";
import { GetInTouchButton } from "./InvestmentBanking";
import FooterTwo from "../../common/Footer/FooterTwo";

const EarlyStageAdvisory = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <ServiceBanner
        title={"Early Stage Advisory"}
        breadcrumbChild={"Early Stage Advisory"}
        breadcrumbMaster={"Services"}
      />
      <EarlyStageAdvisoryServicesAndAbout />
      <TopCtaArea showBorder={true} color={"grey-bg-4"} />
      <PriceDetails />
      <FooterTwo />
    </>
  );
};

export default EarlyStageAdvisory;
