import React from "react";
import Brands from "../../common/Brands/Brands";
import Contact from "../../common/Contact/Contact";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import Testimonial from "../../common/Testimonial/Testimonial";
import OurService from "./OurService";
import ServiceAbout from "./ServiceAbout";
import ServiceBanner from "./ServiceBanner";
import InvestmentBankingServicesAndAbout from "../../InvestmentBanking/InvestmentBankingServicesAndAbout";
import { Link } from "react-router-dom";
import FooterTwo from "../../common/Footer/FooterTwo";
import HighlightsRow from "../../common/HighlightsRow";

const InvestmentBanking = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <ServiceBanner
        title={"Investment Banking"}
        breadcrumbChild={""}
        breadcrumbMaster={""}
      />
      <InvestmentBankingServicesAndAbout />
      <GetInTouchButton />
      <HighlightsRow
        highlights={[
          {
            image: "/assets/img/bg/IB-Grid-Deal Structuring.jpg",
            title: "Deal Structuring",
            subTitle: "Crafting a strategic financing mix",
          },
          {
            image: "/assets/img/bg/Negotitaion.jpg",
            title: "Negotiations",
            subTitle: "Securing favourable terms via strategic negotiations",
          },

          {
            image: "/assets/img/bg/Networking.jpg",
            title: "Strategic Networking",
            subTitle: "Leveraging connections to drive business expansion",
          },

          {
            image: "/assets/img/bg/Debt Syndication.jpg",
            title: "Debt Syndication",
            subTitle:
              "Assisting our clients in raising debt from domestic lenders",
          },
        ]}
      />
      <Testimonial />
      <Brands />
      <FooterTwo />
    </>
  );
};

export default InvestmentBanking;
export function GetInTouchButton() {
  return (
    <div className="container pt-40 pb-40">
      <div className="row">
        <div className="d-flex align-items-center justify-content-center w-100">
          <div className="tp-theme-btn d-flex align-items-center">
            <Link to="/contact">
              <div className="tp-btn-inner">
                <p className="mb-0">Let’t Talk</p>
                <b>Get in Touch</b>
                <span className="d-inline-block">
                  <svg
                    width="46"
                    height="8"
                    viewBox="0 0 46 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M45.3536 4.35355C45.5488 4.15829 45.5488 3.84171 45.3536 3.64645L42.1716 0.464466C41.9763 0.269204 41.6597 0.269204 41.4645 0.464466C41.2692 0.659728 41.2692 0.976311 41.4645 1.17157L44.2929 4L41.4645 6.82843C41.2692 7.02369 41.2692 7.34027 41.4645 7.53553C41.6597 7.7308 41.9763 7.7308 42.1716 7.53553L45.3536 4.35355ZM0 4.5H45V3.5H0V4.5Z"
                      fill="currentColor"
                    />
                  </svg>
                  <svg
                    width="46"
                    height="8"
                    viewBox="0 0 46 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M45.3536 4.35355C45.5488 4.15829 45.5488 3.84171 45.3536 3.64645L42.1716 0.464466C41.9763 0.269204 41.6597 0.269204 41.4645 0.464466C41.2692 0.659728 41.2692 0.976311 41.4645 1.17157L44.2929 4L41.4645 6.82843C41.2692 7.02369 41.2692 7.34027 41.4645 7.53553C41.6597 7.7308 41.9763 7.7308 42.1716 7.53553L45.3536 4.35355ZM0 4.5H45V3.5H0V4.5Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
