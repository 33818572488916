import React from "react";
import Brands from "../../common/Brands/Brands";
import Contact from "../../common/Contact/Contact";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import Testimonial from "../../common/Testimonial/Testimonial";
import OurService from "./OurService";
import ServiceAbout from "./ServiceAbout";
import ServiceBanner from "./ServiceBanner";
import TransactionSupportAbout from "../../TransactionSupport/TransactionSupportAbout";
import TrasactionSupportServicesSection from "../../TransactionSupport/TrasactionSupportServicesSection";
import FooterTwo from "../../common/Footer/FooterTwo";
import { GetInTouchButton } from "./InvestmentBanking";

const TransactionSupport = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <ServiceBanner
        title={"Transaction Support"}
        breadcrumbChild={"Transaction Support"}
        breadcrumbMaster={"Services"}
      />
      <TransactionSupportAbout />
      <TrasactionSupportServicesSection />
      <GetInTouchButton />
      <FooterTwo />
    </>
  );
};

export default TransactionSupport;
