import React from "react";

const FooterTwo = () => {
  return (
    <footer>
      <div className="tp-footer__area grey-bg-4">
        <div className="tp-footer">
          {/* <!-- main-footer start  --> */}
          <div className="tp-footer__main">
            <div className="container">
              <div className="tp-footer-border pt-100 pb-70">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="tp-footer__widget tp-footer__2 footer-col-2-1 pb-30">
                      <div
                        className="footer-logo mb-30 text-blue"
                        style={
                          {
                            // height: "1px",
                            // width: "100%",
                            // marginBottom: "20px",
                          }
                        }
                      >
                        <img
                          width={"100%"}
                          src="assets/img/logo/tailwind-website-logo.png"
                          alt="logo"
                        />
                      </div>
                      {/* <div
                        style={{
                          backgroundColor: "#fff",
                          height: "1px",
                          width: "100%",
                          marginBottom: "20px",
                        }}
                      ></div> */}
                      <p>Where vision takes flight</p>
                      <p>
                        Part of Tailwind group
                        <br />{" "}
                        <a
                          href={"https://tailwindfin.com"}
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://tailwindfin.com
                        </a>
                      </p>
                    </div>
                  </div>
                  {/* <div className="col-lg-3 col-md-6"></div> */}
                  <div className="col-lg-2 col-md-3">
                    <div className="tp-footer__widget tp-footer__2 footer-col-2-2 pb-30">
                      <h3 className="tp-footer__widget-title text-blue">
                        Quick Links
                      </h3>
                      <ul>
                        {/* <li>
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <a href="/blogs">Blogs</a>
                        </li> */}
                        <li>
                          <a href="/team">Team</a>
                        </li>
                        <li>
                          <a href="/contact">Contact Us</a>
                        </li>
                        {/* <li>
                          <a href="/about">Privacy Policy</a>
                        </li>
                        <li>
                          <a href="/contact">Terms & Conditions</a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="tp-footer__widget tp-footer__2 footer-col-2-2 pb-30">
                      <h3 className="tp-footer__widget-title text-blue">
                        Services
                      </h3>
                      <ul>
                        <li>
                          <a href="/early-stage-advisory">
                            Early Stage Advisory
                          </a>
                        </li>
                        <li>
                          <a href="/investment-banking">Investment Banking</a>
                        </li>
                        <li>
                          <a href="/transaction-support">Transaction Support</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12">
                    <div className="tp-footer__widget tp-footer__2 footer-col-2-3 pb-30">
                      <h3 className="tp-footer__widget-title text-blue">
                        Our Location
                      </h3>
                      <ul>
                        <li>
                          <a href="#">
                            6th Floor, Simba Tower, Vishveshwar Nagar, Near
                            Virwani Industrial Estate, Goregaon East, Mumbai –
                            400063{" "}
                          </a>
                        </li>
                        <li>
                          <a href="tel:+919892182908">+91 9892182908</a>
                        </li>
                        <li>
                          <a href="mailto:connect@tailwindcapital.vc">
                            connect@tailwindcapital.vc
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- main footer end  --> */}
        </div>
      </div>

      {/* <!-- footer copy right --> */}
      <div className="top-footer-copyright pt-30 black-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-12">
              <div className="tp-copyrigh-text mb-30">
                <span>
                  © {new Date().getFullYear()} Tailwind Capital Advisors LLP
                </span>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="tp-footer-social-icon mb-30 text-md-end">
                <ul>
                  <li>
                    <a href="#">
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- footer copyright end  --> */}
    </footer>
  );
};

export default FooterTwo;
