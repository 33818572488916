import React from "react";

export default function CoreTeamSingleSection({
  imageSource,
  name,
  designation,
  description,
  linkedinLink,
  colorGrey = false,
}) {
  return colorGrey ? (
    <div
      className={`team-core-single pt-70 pb-70 ${colorGrey ? "grey-bg-4" : ""}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-5 offset-md-1 d-flex justify-content-center">
            <div className="">
              <h3>{name}</h3>
              <img
                src={"assets/img/team/blue-dash.png"}
                width={"150px"}
                style={{
                  marginTop: "-20px",
                }}
              />
              <p>{designation}</p>
              {description.map((v, i) => (
                <p>{v}</p>
              ))}
              <div className="">
                <a href={`${linkedinLink}`} target="_blank">
                  <i className="fab fa-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-1 d-md-block d-none d-lg-block"></div>
          <div className="col-md-4 col-sm-12">
            <img
              src={imageSource}
              alt=""
              width={"450px"}
              height={"450px"}
              style={{
                objectFit: "cover",
                borderRadius: "48px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={`container team-core-single pt-70 pb-70 ${
        colorGrey ? "grey-bg-4" : ""
      }`}
    >
      <div className="row">
        <div className="col-md-4 offset-md-1 col-sm-12 d-flex justify-content-center">
          <img
            src={imageSource}
            alt=""
            width={"450px"}
            height={"450px"}
            style={{
              objectFit: "cover",
              borderRadius: "48px",
            }}
          />
        </div>
        <div className="col-1 col-md-1 d-md-block d-none d-lg-block"></div>

        <div className="col-md-5 col-sm-12">
          <div className="">
            <h3>{name}</h3>
            <img
              src={"assets/img/team/green-dash.png"}
              width={"150px"}
              style={{
                marginTop: "-20px",
              }}
            />
            <p>{designation}</p>
            {description.map((v, i) => (
              <p>{v}</p>
            ))}
            <div className="">
              <a href={`${linkedinLink}`} target="_blank">
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
