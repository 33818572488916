import React from "react";

export default function HighlightsRow({ highlights }) {
  return (
    <div className="d-flex flex-row highlight-wrapper">
      {highlights.map((highlight, index) => {
        return (
          <div className="highlight-item">
            <div className="background-image">
              <img src={highlight.image} alt="" />
            </div>
            <div className="foreground-content">
              <h3>{highlight.title}</h3>
              <div className="subtitle">{highlight.subTitle}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
