import React from "react";

export default function TrasactionSupportServicesSection() {
  return (
    <div className="ts-services w-100">
      <div className="container">
        <div className="row pt-120 pb-120">
          <div className="col-12 ts-services-white-container">
            <div className="row">
              <div className="col-md-4 service-section-item-center border-right border-bottom">
                <h1>Our Services</h1>
              </div>

              <div className="col-md-4 service-section-item border-bottom border-right d-flex flex-column justify-content-center">
                <h3>FDD</h3>
                <p>
                  Financial Due Diligence (FDD) is a vital step in business
                  transactions, such as investments. It involves a thorough
                  examination of a company's financial records and performance
                  to assess its financial health and risks. We ensure that
                  parties have a clear understanding of a company's financial
                  condition before entering into significant business
                  agreements.
                </p>
              </div>
              <div className="col-md-4 service-section-item border-bottom d-flex flex-column justify-content-center">
                <h3>Valuations</h3>
                <p>
                  Valuation is the process of determining the intrinsic worth of
                  an asset, business, or investment. It involves assessing
                  various factors to arrive at a fair and objective value. Our
                  Valuation techniques ensure detailed valuations to help
                  investors and startups make informed choices and negotiate
                  fair prices, ensuring the efficient allocation of capital and
                  resources.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 service-section-item-center border-right d-flex flex-column justify-content-center">
                <img src={"assets/img/service/tools.png"} />
              </div>
              <div className="col-md-4 service-section-item border-right">
                <h3>SHA / SSA</h3>
                <p>
                  A Shareholders' Agreement (SHA) or a Stockholders' Agreement
                  (SSA) is a legally binding document that outlines the rights,
                  obligations, and relationships among shareholders or
                  stockholders in a company. We have experts to create a
                  well-drafted SHA/SSA to prevent conflicts, protect the rights
                  of Investors and founders and provide clarity on how the
                  business will be run.
                </p>
              </div>
              <div className="col-md-4 service-section-item d-flex">
                <h3>Term Sheets</h3>
                <p>
                  It serves as a preliminary agreement, summarizing the major
                  deal points, such as valuation, ownership, governance, and
                  exit strategies. We help ensure that both sides have a clear
                  understanding of the proposed deal before proceeding with more
                  detailed legal document.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
