import React from "react";

export default function EsaServiceCard({ image, title, description, number }) {
  return (
    <div className="d-flex flex-column w-100 esa-service-card align-items-start">
      <div className="esa-service-card-icon w-100 d-flex align-items-center justify-content-center">
        <img src={image} height={48} width={48} alt="" />
      </div>
      <h4 className="mt-20">{title}</h4>
      <p className="description">{description}</p>
      <h4 className="esa-step-number">{number}</h4>
    </div>
  );
}
