import React from "react";
import Brands from "../../common/Brands/Brands";
import FooterThree from "../../common/Footer/FooterThree";
import Menu from "../../common/Menu/Menu";
import Testimonial from "../../common/Testimonial/Testimonial";
import TeamAbout from "./TeamAbout";
import TeamBanner from "./TeamBanner";
import FooterTwo from "../../common/Footer/FooterTwo";
import CoreTeamSingleSection from "./CoreTeamSingleSection";

const TeamPage = () => {
  const teamData = [
    {
      imageSource: "assets/img/team/Deshna Jain.png",
      name: "Deshna Jain",
      designation: "Managing Partner",
      description: [
        `
    I hold both a Bachelor's and Master's degree in Management with a specialization in Finance, and I'm proud to have been a founding team member of a prominent Angel network. My journey in this ecosystem has helped me build a strong foundation of knowledge and expertise that I plan to leverage in this venture.`,

        `Having been on the VC side in the past, I've witnessed firsthand the exhilarating highs and daunting challenges that founders face when navigating the exhaustive process of fundraising. It's a journey that demands not just dedication, but also strategic insight and a supportive network.`,

        `Now, I'm on a mission to leverage my invaluable experience to make this journey smoother for budding entrepreneurs. My passion lies in helping founders realize their visions by providing them with the guidance and resources they need to secure the funding required to scale their ventures.`,
      ],
      linkedinLink: " h]ps://www.linkedin.com/in/deshna-jain-77ab67141/",
      colorGrey: false,
    },
    {
      imageSource: "assets/img/team/Vivek Goel.png",
      name: "Vivek Goel",
      designation: "Managing Partner",
      description: [
        `
      I am a CA, CFA charterholder, certified FRM and MBA in Finance & Strategy from IIM Lucknow. Thereafter, I have worked at Kotak Investment Advisors Ltd for over 4 years as a Portfolio Advisory growing over time to a team lead and managing over $1Bn in assets across multiple clients including large business houses, family offices, CXOs and leading professionals.`,

        `Having a desire to do more, I joined Tailwind Financial Services as the Co-founder & Jt Managing Director to build my own wealth management company. It was here that we introduced our clients to unique products to diversify their portfolios. `,

        `Among these, early stage investments caught our interest, not only with the returns potential but with the value being created by the entire ecosystem. This is how my conviction to work deeper in the startup ecosystem grew and together with our team evolved into Tailwind Capital Advisors.

      `,
      ],
      linkedinLink: "https://www.linkedin.com/in/vivek-goel/",
      colorGrey: true,
    },

    {
      imageSource: "assets/img/team/Rishabh Goel.png",
      name: "Rishabh Goel",
      designation: "Managing Partner",
      description: [
        `I have been a serial entrepreneur throughout my journey of now over a decade. Beginning my entrepreneurial stint in 2013, I started at an early age of 17. My first step was building an E-comm platform that aimed to meet the demand from Indians for ethnic food all over the globe.Thereafter, the second and current venture started about 3 years later. In Sept 2016, I ventured into Wealth Management, and Tailwind Financial Services was born. Our work was centred around providing solutions for all the financial needs.`,

        `Seeing the power and growth of the startup ecosystem, I am excited to work with similarly inclined entrepreneurs that aim to solve a problem in their own unique way. Our endeavour is for startups to achieve their potential and use our experience to share some of the insights we have gained in this decade long journey.`,
      ],
      linkedinLink: "hh]ps://www.linkedin.com/in/rishabhgoel-/",
      colorGrey: false,
    },
  ];
  return (
    <>
      <Menu header_style_2={true} />
      <TeamBanner />
      {teamData.map((teamMember, i) => (
        <CoreTeamSingleSection {...teamMember} key={i} />
      ))}
      {/* <TeamAbout /> */}
      <Testimonial />
      {/* <Brands /> */}
      <FooterTwo />
    </>
  );
};

export default TeamPage;
