import React from "react";
import About from "../Pages/About/About";

export default function InvestmentBankingServicesAndAbout() {
  return (
    <div className="container pt-70 pb-40">
      <div className="row">
        <div className="col-md-8">
          <AboutDescription />
        </div>
        <div className="col-md-4">
          <Services />
        </div>
      </div>
    </div>
  );
}
function Services() {
  const services = [
    "Preliminary Evaluation",
    "Investment Documentation",
    "Prospective Investor Identification",
    "Investor Interaction and Negotiation",
    "Tranaction Execution",
    "Compliance Support",
  ];

  return (
    <div className="ib-services-container">
      <h2 className="tp-section-title">Process</h2>
      {services.map((service, i) => (
        <div className="service-pill" key={i}>
          {service}
        </div>
      ))}
    </div>
  );
}

function AboutDescription() {
  return (
    <div className="ib-about-container container">
      <img
        src="assets/img/service/ib/ib-about.png"
        alt=""
        className="ib-about-img"
      />
      <h2 className="pt-40 tp-section-title">Investment Banking </h2>
      <p>
        Our Investment Banking service is designed to take your startup to new
        heights. We don't just provide advice; we become your strategic
        partners, working hand-in-hand to secure the capital your venture needs.
        From the initial fundraising strategy to connecting you with the right
        investors, structuring deals, and managing the entire end-to-end
        fundraising process, we ensure that your startup gets the attention and
        resources it deserves.
        <br />
        <br />
        <b>Our Comprehensive Approach</b>
        <br />
        We understand that the journey of fundraising can be both exciting and
        challenging. That's where we step in to offer comprehensive support at
        every stage:
        <br />
        <br />
        1. <b>Fundraising Strategy Development:</b> We start by collaboratively
        developing a tailored fundraising strategy that aligns with your
        short-term and long-term goals. Our team of seasoned professionals helps
        you determine the optimal funding round, amount, and approach, ensuring
        that your startup is well-positioned in the competitive funding
        landscape.
        <br />
        <br />
        2. <b>Investor Connections:</b> Navigating the vast sea of potential
        investors can be daunting. We simplify the process by leveraging our
        extensive network, connecting you with the right investors who share
        your vision. We help you build relationships with venture capital firms,
        angel investors, and strategic partners who believe in your mission.
        <br />
        <br />
        3.<b> Deal Structuring: </b>Crafting the right mix of equity, debt, or
        hybrid financing instruments is crucial. We specialize in deal
        structuring, ensuring that your fundraising is optimized for your unique
        business needs. We balance your financial requirements with investor
        expectations, creating a win-win proposition.
        <br />
        <br />
        4. <b>End-to-End Fundraising Management:</b> From preparing a compelling
        pitch to conducting due diligence and managing the entire fundraising
        process, we're with you every step of the way. Our expert guidance
        ensures a seamless fundraising journey, allowing you to focus on what
        you do best—growing your startup.
        <br />
        <br />
        5. <b>Attention and Resources:</b> We believe that your startup deserves
        the attention and resources required for success. Through our extensive
        network and deep industry knowledge, we position your venture in front
        of the right audience, giving it the spotlight it deserves.
        <br />
      </p>
    </div>
  );
}
