import React from "react";
import EsaServiceCard from "./EsaServiceCard";

export default function EarlyStageAdvisoryServicesAndAbout({ highlights }) {
  return (
    <div className="container pt-70 pb-70">
      <div className="row">
        <div className="esa-about-left col-md-12 col-lg-3 offset-lg-1">
          <div className="d-flex flex-row">
            {/* <div className="hyphen"></div> */}
            <h5 className="hyphenated">EXPLORE OUR SERVICES</h5>
          </div>
          <h2>Qualified for your needs</h2>
          <p>
            We are your seasoned navigators, providing expert guidance on every
            aspect of your fundraising journey. From crafting the perfect pitch
            deck to mapping out your fundraise strategy, creating robust
            financial forecasts, and offering insights on how to effectively go
            to market, we are with you every step of the way
          </p>
        </div>
        <div className="esa-about-right col-md-12 col-lg-7">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <EsaServiceCard
                image={"assets/img/icons/service-2.png"}
                title={"Financial Forecast Creation"}
                description={
                  "Anticipate financial trends, optimize resource allocation, secure funding, and make strategic leaps with confidence"
                }
                // number={"01"}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <EsaServiceCard
                image={"assets/img/icons/service-2.png"}
                title={"Fundraising Mapping"}
                description={
                  "Chart out strategic pathways to secure capital for your ventures"
                }
                // number={"02"}
              />
            </div>
          </div>
          <div className="row  mt-40">
            <div className="col-md-6 col-sm-12">
              <EsaServiceCard
                image={"assets/img/icons/service-2.png"}
                title={"Pitch Deck - Advisory & Creation"}
                description={
                  "Rely on our unique approach to ensure your message communicates impactfully with investors"
                }
                // number={"03"}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <EsaServiceCard
                image={"assets/img/icons/service-2.png"}
                title={"GTM, Business and Financial Model Advisory"}
                description={
                  "Optimize your market approach, enhance your business model and ensure financial viability"
                }
                // number={"04"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
