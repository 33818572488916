import React from "react";

const PriceDetails = () => {
  return (
    <>
      <div className="tp-price-table pt-130">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-md-6">
              <div className="price-table__item pt-50 pb-40 pl-40 pr-40 price-border mb-30">
                <div className="price-table__top d-flex justify-content-between mb-50">
                  <h4 className="text-black">BASIC</h4>
                  <span>
                    <i className="pe-7s-cup"></i>
                  </span>
                </div>
                <div className="price-table__content">
                  <div className="price-table__list mb-55">
                    <ul>
                      <li>
                        <span>
                          <i className="fal fa-check"></i>
                        </span>
                        Financial Forecast Creation
                      </li>
                      <li>
                        <span>
                          <i className="fal fa-check"></i>
                        </span>
                        Fundraising Mapping
                      </li>
                      <li>
                        <span>
                          <i className="fal fa-check"></i>
                        </span>
                        Pitch Presentation Advisory
                      </li>
                      <li className="disabled">
                        <span>
                          <i className="fal fa-check"></i>
                        </span>
                        Pitch Presentation Crafting
                      </li>
                      <li className="disabled">
                        <span>
                          <i className="fal fa-check"></i>
                        </span>
                        GTM, Business and Financial Model Advisory
                      </li>
                    </ul>
                  </div>
                  <a className="tp-purchase-btn" href="/contact">
                    GET IN TOUCH{" "}
                    <span>
                      <svg
                        width="22"
                        height="8"
                        viewBox="0 0 22 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      <svg
                        width="22"
                        height="8"
                        viewBox="0 0 22 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="price-table__item pt-50 pb-40 pl-40 pr-40 price-border mb-30">
                <div className="price-table__top d-flex justify-content-between mb-50">
                  <h4 className="text-black">ESSENTIALS</h4>
                  <span>
                    <i className="pe-7s-diamond"></i>
                  </span>
                </div>
                <div className="price-table__content">
                  <div className="price-table__list mb-55">
                    <ul>
                      <li>
                        <span>
                          <i className="fal fa-check"></i>
                        </span>
                        Financial Forecast Creation
                      </li>
                      <li>
                        <span>
                          <i className="fal fa-check"></i>
                        </span>
                        Fundraising Mapping
                      </li>
                      <li>
                        <span>
                          <i className="fal fa-check"></i>
                        </span>
                        Pitch Presentation Advisory
                      </li>
                      <li>
                        <span>
                          <i className="fal fa-check"></i>
                        </span>
                        Pitch Presentation Crafting
                      </li>
                      <li className="disabled">
                        <span>
                          <i className="fal fa-check"></i>
                        </span>
                        GTM, Business and Financial Model Advisory
                      </li>
                    </ul>
                  </div>
                  <a className="tp-purchase-btn" href="/contact">
                    GET IN TOUCH{" "}
                    <span>
                      <svg
                        width="22"
                        height="8"
                        viewBox="0 0 22 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      <svg
                        width="22"
                        height="8"
                        viewBox="0 0 22 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="price-table__item pt-50 pb-40 pl-40 pr-40 price-border mb-30">
                <div className="price-table__top d-flex justify-content-between mb-50">
                  <h4 className="text-black">PROFESSIONAL</h4>
                  <span>
                    <i className="pe-7s-star"></i>
                  </span>
                </div>
                <div className="price-table__content">
                  <div className="price-table__list mb-55">
                    <ul>
                      <li>
                        <span>
                          <i className="fal fa-check"></i>
                        </span>
                        Financial Forecast Creation
                      </li>
                      <li>
                        <span>
                          <i className="fal fa-check"></i>
                        </span>
                        Fundraising Mapping
                      </li>
                      <li>
                        <span>
                          <i className="fal fa-check"></i>
                        </span>
                        Pitch Presentation Advisory
                      </li>
                      <li>
                        <span>
                          <i className="fal fa-check"></i>
                        </span>
                        Pitch Presentation Crafting
                      </li>
                      <li>
                        <span>
                          <i className="fal fa-check"></i>
                        </span>
                        GTM, Business and Financial Model Advisory
                      </li>
                    </ul>
                  </div>
                  <a className="tp-purchase-btn" href="/contact">
                    GET IN TOUCH{" "}
                    <span>
                      <svg
                        width="22"
                        height="8"
                        viewBox="0 0 22 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      <svg
                        width="22"
                        height="8"
                        viewBox="0 0 22 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceDetails;
