const menu_data = [
  {
    id: 1,
    title: "Home",
    link: "/",
  },
  {
    id: 2,
    title: "Team",
    link: "/team",
  },
  {
    id: 3,
    hasDropdown: true,
    title: "services",
    link: "/",
    submenus: [
      { title: "Investment Banking", link: "/investment-banking" },
      { title: "Early Stage Advisory", link: "/early-stage-advisory" },
      { title: "Transaction Support", link: "/transaction-support" },
    ],
  },
  // {
  //   id: 4,
  //   title: "Transactions",
  //   link: "/#",
  // },
  // {
  //   id: 5,
  //   title: "Blog",
  //   link: "/blog",
  // },
  {
    id: 5,
    title: "Contact Us",
    link: "/contact",
  },
];

export default menu_data;
