import React from "react";
import { Link } from "react-router-dom";

const Menus = () => {
  return (
    <ul>
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="/team">Team</Link>
      </li>
      <li className="has-dropdown">
        <Link to="/team">Services</Link>
        <ul className="submenu">
          <li>
            <Link to="/investment-banking">Investment Banking</Link>
          </li>
          <li>
            <Link to="/early-stage-advisory">Early Stage Advisory</Link>
          </li>
          <li>
            <Link to="/transaction-support">Transaction Support</Link>
          </li>
        </ul>
      </li>
      {/* <li>
        <Link to="/#">Transactions</Link>
      </li> */}
      {/* <li className="has-dropdown">
        <Link to="/portfolio">Portfolio</Link>
        <ul className="submenu">
          <li>
            <Link to="/portfolio">Portfolio</Link>
          </li>
          <li>
            <Link to="/portfolio-details">Portfolio Details</Link>
          </li>
        </ul>
      </li> */}
      {/* <li>
        <Link to="/blog">Blogs</Link>
      </li> */}
      <li>
        <Link to="/contact">Contact Us</Link>
      </li>
    </ul>
  );
};

export default Menus;
